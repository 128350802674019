@import "antd/dist/reset.css";

* {
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-decoration: none;
}

#webpack-dev-server-client-overlay {
  display: none;
}
